import ApiProxy from 'api/lib/ApiService';
import ApiResponse from 'api/lib/models/ApiResponse';
import ApiProxyV2 from 'api/lib/ApiService/v2';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { ListCorporation } from 'interfaces/dataLists/listCorporation';
import { ListLevelOfCare } from 'interfaces/dataLists/listLevelOfCare';
import { ListFacility } from 'interfaces/dataLists/listFacility';
import { ListForm } from 'interfaces/dataLists/listForm';
import { ListSystemAlert, SystemAlertData } from 'interfaces/dataLists/listSystemAlert';
import { ListOption } from 'interfaces/dataLists/listOption';
import { ListState } from 'interfaces/dataLists/listState';
import { ListPopulation } from 'interfaces/dataLists/listPopulation';
import { ListFacilityProgram, ListProgram } from 'interfaces/dataLists/listFacilityProgram';
import { URLS } from 'constants/appUrls';
import { ListAccessType } from '../../interfaces/dataLists/listAccessType';

export enum DataListType {
  AlertType = 'AlertType',
  ClientType = 'ClientType',
  Gender = 'Gender',
  Platform = 'Platform',
  Program = 'Program',
  Race = 'Race',
  Relationship = 'Relationship',
  Severity = 'Severity',
  Status = 'Status',
  UserRole = 'UserRole',
  DateRangeType = 'DateRangeType',
  SampleType = 'SampleType',
  AgeRangeType = 'AgeRangeType',
  AssessmentCompletionType = 'AssessmentCompletionType',
  SdohCompletion = 'SDOHCompletionType',
}

export enum ProgramListMode {
  Group = 'Group',
  CoreMeasures = 'CoreMeasures',
}

class DataListsProxy {
  static getUserCorporationList(): Promise<ListCorporation[]> {
    const url = `${URLS.MHO_API}/ListCorp`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListCorporation[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of Corporations associated with the user.
   *
   * @returns {Promise} a list of corporations.
   */
  static getUserCorpList(): Promise<ListCorporation[]> {
    const url = `${URLS.MHO_API}/ListCorp`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListCorporation[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of Level of Care associated with the user.
   *
   * @returns {Promise} a list of Level of Care.
   */
  static getLevelOfCareList(): Promise<ListLevelOfCare[]> {
    const url = `${URLS.MHO_API}/ListLoC`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListLevelOfCare[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of Level of Care associated with the user.
   *
   * @returns {Promise} a list of Level of Care.
   */
  static getListPopulation(): Promise<ListPopulation[]> {
    const url = `${URLS.MHO_API}/ListPopulation`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListPopulation[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve a list of facilities associated with the corporationID.
   * NOTE: This removes the 'All Facilities', with ID 0 temporarily, since we can't treat it as a normal facility. This should
   * be done in the UI only. Otherwise it muchs up things like ordering and facility selections.
   *
   * @param corporationID optional corporation ID associated with the returned facilities.
   * @returns {Promise} list of found facilities associated with the corporationID.
   */
  static getUserFacilityList(corporationID?: number): Promise<ListFacility[]> {
    const options = corporationID === undefined ? '' : `&corpid=${corporationID}`;
    // JBC UI-API hack - use 'mode=Header' to remove 'All Facilities' from the db API call. Having 'All Facilities'
    // in the database disrupts the display and flow, as its treated like
    // any other real facilities. Remove it here, and treat it as a UI entity instead.
    // * Update: 'mode=Header' only is enabled for 'Facility Executive' and 'Facility Director'.
    //   So set to filter all facilityID = 0.
    const url = `${URLS.MHO_API}/ListFacility?mode=Header${options}`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListFacility[]>(
        url,
        (response: any) => {
          let filteredList: ListFacility[] = [];
          if (response.data) {
            filteredList = response.data.filter((facility: ListFacility) => facility.facilityID !== 0);
          }
          resolve(filteredList);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  // Deprecated -- Use getUserFacilityList() instead.
  static getAllUserFacilities(success: (e: ApiResponse<ListFacility[]>) => void, failure: (e: ApiResponse<ListFacility[]>) => void): void {
    const url = `${URLS.MHO_API}/ListFacility`;

    ApiProxy.get<ListFacility[]>(
      url,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static getAllUserFacilitiesV2(patientId?: number): Promise<ApiResponseV2<ListFacility[]>> {
    const url = `${URLS.MHO_API}/ListFacility`;
    const params: Record<string, string> = {};
    if (patientId) {
      params.pid = patientId.toString();
    }
    return ApiProxyV2.get<ListFacility[]>(url, params);
  }

  static getFacilityFormList(facilityID: number): Promise<ApiResponseV2<ListForm[]>> {
    const url = `${URLS.MHO_API}/PrintForm/${facilityID}`;
    return ApiProxyV2.get<ListForm[]>(url);
  }

  static getSystemAlertsList(facilityID?: number, severityID?: number, clientTypeID?: number, platformID?: number): Promise<ListSystemAlert[]> {
    const pid = platformID;
    return new Promise((resolve, reject) => {
      const optionsList: string[] = [];
      let optionsStr = '';
      if (platformID) {
        optionsList.push(`platformid=${pid}`);
      }
      if (facilityID) {
        optionsList.push(`fid=${facilityID}`);
      }
      if (severityID) {
        optionsList.push(`severityid=${severityID}`);
      }
      if (clientTypeID) {
        optionsList.push(`clientTypeid=${clientTypeID}`);
      }
      if (optionsList.length) {
        optionsStr += `?${optionsList.join('&')}`;
      }

      const url = `${URLS.MHO_API}/ListSysAlerts${optionsStr}`;

      ApiProxy.get<ListSystemAlert[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  /**
   * Retrieve the raw PDF data from the API.
   *
   * @param hash The hash of the file to pull from the API.
   * @returns {Promise} The PDF file as a blob.
   */
  static getDownloadPDFForm(hash: string): Promise<ApiResponseV2<Blob>> {
    const url = `${URLS.MHO_API}/DLPdf/${hash}`;
    return ApiProxyV2.get<Blob>(url, undefined, true);
  }

  /**
   * Retrieve the raw PDF data from the API.
   *
   * @param hash The hash of the file to pull from the API.
   * @returns {Promise} The PDF file as a blob.
   */
  static downloadHelpPDF(hash: string): Promise<ApiResponseV2<Blob>> {
    const url = `${URLS.MHO_API}/HelpFile/${hash}`;
    return ApiProxyV2.get<Blob>(url, undefined, true);
  }

  static getOptionList(optionList: DataListType, success: (e: ApiResponse<ListOption[]>) => void, failure: (e: ApiResponse<ListOption[]>) => void): void {
    const url = `${URLS.MHO_API}/ListLookup?category=${optionList}`;

    ApiProxy.get<ListOption[]>(
      url,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static getOptionListV2(optionList: DataListType): Promise<ApiResponseV2<ListOption[]>> {
    const url = `${URLS.MHO_API}/ListLookup?category=${optionList}`;
    return ApiProxyV2.get<ListOption[]>(url);
  }

  static getStateList(success: (e: ApiResponse<ListState[]>) => void, failure: (e: ApiResponse<ListState[]>) => void): void {
    const url = `${URLS.MHO_API}/States`;
    ApiProxy.get<ListState[]>(
      url,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static getStateListV2(): Promise<ApiResponseV2<ListState[]>> {
    const url = `${URLS.MHO_API}/States`;
    return ApiProxyV2.get<ListState[]>(url);
  }

  static getFacilityPrograms(corpID?: number): Promise<ListFacilityProgram[]> {
    const apiArgList = [];
    if (corpID) {
      apiArgList.push(`corpid=${corpID}`);
    }
    const apiArgStr = apiArgList.length ? `?${apiArgList.join('&')}` : '';
    const url = `${URLS.MHO_API}/ListFacProg${apiArgStr}`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListFacilityProgram[]>(
        url,
        (response: any) => {
          // JBC UI-API hack - remove the 'All Facilities' from the db response, until the db is updated.
          let filteredList: ListFacilityProgram[] = [];
          if (response.data) {
            filteredList = response.data.filter((facilityProgram: ListFacilityProgram) => facilityProgram.facilityID !== 0);
          }
          resolve(filteredList);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static getFacilityProgramsByFaciiltyIDs(facilityIDs: number[] = []): Promise<ListFacilityProgram[]> {
    const url = `${URLS.MHO_API}/ListFacProg`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<ListFacilityProgram[]>(
        url,
        (response: any) => {
          // JBC UI-API hack - remove the 'All Facilities' from the db response, until the db is updated.
          let filteredList: ListFacilityProgram[] = [];
          if (response.data) {
            filteredList = response.data.filter((facilityProgram: ListFacilityProgram) => {
              const isIncluded = facilityProgram.facilityID !== 0 && (!facilityIDs.length || facilityIDs.includes(facilityProgram.facilityID));
              return isIncluded;
            });
          }
          resolve(filteredList);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static getPrograms(facilityID: number, mode?: ProgramListMode): Promise<ListProgram[]> {
    return new Promise((resolve, reject) => {
      let url = `${URLS.MHO_API}/ListProg?fid=${facilityID}`;

      if (mode) {
        url += `&mode=${mode}`;
      }

      ApiProxy.get<ListProgram[]>(
        url,
        (response: any) => {
          resolve(response?.data || []);
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static postSystemAlert(data: SystemAlertData[], success: (e: ApiResponse<SystemAlertData>) => void, failure: (e: ApiResponse<SystemAlertData>) => void): void {
    const url = `${URLS.MHO_API}/SystemAlerts`;

    ApiProxy.post<SystemAlertData>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static getAccessTypes(facilityId: number, accountId: number | null, mode: string, success: (e: ApiResponse<ListAccessType[]>) => void, failure: (e: ApiResponse<ListAccessType[]>) => void): void {
    let url = `${URLS.MHO_API}/AccsType?fid=${facilityId}&mode=${mode}`;
    if (accountId !== null) {
      url += `&acctid=${accountId}`;
    }

    ApiProxy.get<ListAccessType[]>(
      url,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }
}

export default DataListsProxy;
