import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import ExternalLink from 'global_elements/Navigation/ExternalLink';
import Paragraph from 'global_elements/Text/Paragraph';

import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { MentalHealthResourceCardProps } from 'types/cardProps';

const MentalHealthResourceCard = (props: MentalHealthResourceCardProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_COL}
    justify={JustifyVariant.START}
    align={AlignVariant.START}
    extraClasses="mental-health-resource-card-wrapper"
  >
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      justify={JustifyVariant.SPACE_BETWEEN}
      align={AlignVariant.START}
      extraClasses="mental-health-resource-card-wrapper__header"
    >
      <TertiaryHeader text={props.title} fontColor={FontColors.DARK} />
      <ExternalLink
        isPhoneNumber={props.type.toLowerCase().includes('phone') || props.type.toLowerCase().includes('text')}
        href={props.resourceLinkText}
        decorativeLink={props.type.toLowerCase() === 'decorative'}
      >
        <InlineText
          text={props.resourceLinkLabel}
          fontSize={FontSizes.REGULAR}
          fontColor={FontColors.DARK}
          underlined
        />
      </ExternalLink>
    </FlexContainer>
    <Paragraph
      text={props.description}
      fontColor={FontColors.DARK}
      fontSize={FontSizes.REGULAR}
      dangerouslySetInnerHTML
    />
  </FlexContainer>
);

export default React.memo(MentalHealthResourceCard);
