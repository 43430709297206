import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import UserProxy from 'api/user/userProxy';
import UserAccount from 'interfaces/users/userAccount';
import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import LabledAsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect/asyncLabledSingleSelct';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { DualListPanel } from 'global_elements/Inputs/DualListPanel';
import { DropdownMenu } from 'global_elements/Inputs/DropdownMenu';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { UserContext } from 'context/user';
import { SelectOption } from 'types/inputProps';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ListFacilityProgram } from 'interfaces/dataLists/listFacilityProgram';
import UserEntitlement from 'interfaces/users/userEntitlement';
import { SendEmailInvitationRequest } from 'interfaces/facilityUsers/sendEmailInvitationRequest';
import { isUHSEmail } from 'global_elements/Inputs/TextInput/lib/email';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import { FacilityUserPage, FacilityUserPageUrlParams } from '../Facility/FacilityUser/FacilityUser';
import './styles.scss';
import PopupWindow from '../../../global_elements/Layouts/PopupWindow';

const _ = require('lodash');

class FacilityUserEditPage extends FacilityUserPage {
  constructor(props: RouteComponentProps<FacilityUserPageUrlParams>) {
    super(props, false);
  }

  componentDidMount = (): void => {
    const optionsPromise = this.intitializeStatuses();
    const programPromise = this.initializePrograms();
    this.checkIfHasLoggedIn();

    Promise.all([optionsPromise, programPromise])
      .then(() => {
        this.getUserInformation();
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  componentDidUpdate = (): void => {
    // Set the account status, once the information is available
    if (this.state.accountStatus.label === '' && this.state.accountStatus.value !== '') {
      const selectedStatus = this.state.statusOptions.find((status) => status.value === this.state.accountStatus.value);
      if (selectedStatus) {
        this.setState((current) => ({
          ...current,
          accountStatus: selectedStatus,
        }));
      }
    }
  };

  /**
   * Initialize the statuses.
   *
   * @returns {Promise} true once statuses are set.
   */
  intitializeStatuses = (): Promise<boolean> => new Promise((resolve, reject) => {
    DataListsProxy.getOptionList(
      DataListType.Status,
      (statusResponse) => {
        const loadedStatuses: SelectOption[] = [];

        if (statusResponse?.data) {
          statusResponse.data.forEach((element) => {
            if (element.lookupID && element.lookupDesc) {
              loadedStatuses.push({ label: element.lookupDesc, value: element.lookupID.toString() });
            }
          });
        }

        this.setState(
          (current) => ({
            ...current,
            statusOptions: loadedStatuses,
          }),
          () => {
            resolve(true);
          },
        );
      },
      (statusErrorResponse) => {
        console.log(statusErrorResponse);
        reject(statusErrorResponse);
      },
    );
  });

  hasSelectedFacility = (entitlement: any): boolean => {
    const foundPrograms = this.state.facilityProgramLists.find((program) => entitlement.facilityID === program.facilityID);
    return !!foundPrograms;
  };

  checkIfHasLoggedIn = (): void => {
    const accountID = parseInt(this.props.match.params.accountID, 10);
    UserProxy.getUserAccountInfo(
      accountID,
      (accountResponse) => {
        if (!accountResponse?.data) {
          return;
        }

        const accountInfo: UserAccount | null = accountResponse.data[0] ?? null;
        this.setState((current) => ({
          ...current,
          hasLoggedIn: accountInfo.lastLoginDate !== null,
        }));
      },
      (accountErrorResponse) => {
        console.log(accountErrorResponse);
      },
    );
  };

  getUserInformation = (): void => {
    const accountID = parseInt(this.props.match.params.accountID, 10);
    UserProxy.getUserAccountInfo(
      accountID,
      (accountResponse) => {
        if (!accountResponse?.data) {
          return;
        }

        const accountInfo: UserAccount | null = accountResponse.data[0] ?? null;

        if (accountInfo !== null) {
          const selectedStatus = this.state.statusOptions.find((status) => parseInt(status.value, 10) === accountInfo.statusID) ?? { label: '', value: `${accountInfo.statusID}` };

          const role = {
            label: accountInfo.userRoleName,
            value: accountInfo.userRoleID.toString(),
          };
          const accessType = {
            label: accountInfo.accessType,
            value: accountInfo.accessTypeID?.toString(),
          };

          this.setState((current) => ({
            ...current,
            firstName: accountInfo.firstName,
            lastName: accountInfo.lastName,
            email: accountInfo.accountEmail,
            accountStatus: selectedStatus,
            userRole: role,
            accessType,
          }));
        }

        UserProxy.getUserEntitlements(accountID)
          .then((userEntitlement: UserEntitlement[]) => {
            if (userEntitlement.length) {
              let corporationID = -1;
              let corporationName = '';
              const facilityIDList: number[] = []; // Facility IDs of any entitlement with programID == 0
              const selectedProgramsIDsList: number[] = [];

              userEntitlement.forEach((entitlement) => {
                if (entitlement.programID === 0) {
                  facilityIDList.push(entitlement.facilityID);
                } else {
                  selectedProgramsIDsList.push(entitlement.programID);
                }
              });

              let programPromise: Promise<ListFacilityProgram[]>;
              if (facilityIDList.length) {
                programPromise = DataListsProxy.getFacilityProgramsByFaciiltyIDs(facilityIDList);
              } else {
                programPromise = Promise.resolve([]);
              }

              programPromise
                .then((filteredPrograms: ListFacilityProgram[]) => {
                  const programIDList: number[] = filteredPrograms.map((program) => program.programID);
                  const selectedProgramIDs = _.union(selectedProgramsIDsList, programIDList);
                  this.setState((current) => ({ ...current, selectedProgramIDs }));
                })
                .catch((error) => {
                  console.log(error);
                });

              // Find the corp ID and name with the the selected facility/program.
              userEntitlement.find((entitlement) => {
                if (this.hasSelectedFacility(entitlement)) {
                  corporationID = entitlement.corporationID;
                  corporationName = entitlement.corporationName;
                  this.setState((current) => ({
                    ...current,
                    corporation: { label: corporationName, value: `${corporationID}` },
                  }));
                  return true;
                }
                return false;
              });
            }

            this.setState((current) => ({
              ...current,
              loading: false,
            }));
          })
          .catch((entitlementsErrorResponse) => {
            console.log(entitlementsErrorResponse);
          });
      },
      (accountErrorResponse) => {
        console.log(accountErrorResponse);
      },
    );
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };

  handleStatusSelectChange = (option: SelectOption): void => {
    this.setState((current) => ({
      ...current,
      accountStatus: option,
    }));
  };

  saveChanges = (): void => {
    this.afterSaveProcess(true, this.state.accountStatus.label);
  };

  discardChanges = (): void => {
    this.returnToFacilityUserAccounts();
  };

  submitChanges = (): void => {
    const accountID = parseInt(this.props.match.params.accountID, 10);
    const statusID = parseInt(this.state.accountStatus.value, 10);
    this.submitUserChanges(accountID, statusID)
      .then(() => {
        this.saveChanges();
      })
      .catch((error: any) => {
        console.log(error);
        this.setState((current) => ({
          ...current,
          saveError: true,
          saveErrorMessage: error.message,
        }));
      });
  };

  resendEmailInvitation = (): void => {
    this.setState((current) => ({
      ...current,
      saveError: false,
      saveErrorMessage: undefined,
    }));

    const sendEmailInvitationRequest: SendEmailInvitationRequest = {
      Name: `${this.state.firstName} ${this.state.lastName}`,
      Email: this.state.email,
      Status: '',
    };

    UserProxy.SendEmailInvitation(
      sendEmailInvitationRequest,
      () => {
        this.returnToFacilityUserAccounts();
      },
      (invitationErrorResponse) => {
        this.setState((current) => ({
          ...current,
          saveError: true,
        }));
        console.log(invitationErrorResponse);
      },
    );
  };

  render(): JSX.Element {
    return (
      <UserContext.Consumer>
        {() => (
          <PageLayout
            layout={PageLayoutVariant.PADDED}
            testText="Facility User Edit Page"
            loadingTitle="Edit Facility User Account"
            loadingText={this.state.loading ? 'Retrieving User Account Info...' : undefined}
          >
            <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="non-patient-edit-content">
              <PrimaryHeader
                text="Edit Facility User Account"
                fontColor={FontColors.PRIMARY}
                // ***Adding space 2/2024
                marginBottomPx={16}
              />
              <form>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
                  <LabledTextInput
                    label="First Name"
                    placeholder="User First Name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.emptyFirstNameError}
                    error={this.state.firstNameError ? 'Enter a First Name' : undefined}
                  />
                  <LabledTextInput
                    label="Last Name"
                    placeholder="User Last Name"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.emptyLastNameError}
                    error={this.state.lastNameError ? 'Enter a Last Name' : undefined}
                  />
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN}>
                  <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
                    <Paragraph text="Email" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
                    <Paragraph text={this.state.email} fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} />
                  </FlexContainer>
                  {!isUHSEmail(this.state.email) && (
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.START} extraClasses="button-container">
                      <Button variant={ButtonVariant.PRIMARY} onClick={this.resendEmailInvitation} disabled={this.state.hasLoggedIn}>
                        <InlineText text="Resend Invitation" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                      </Button>
                    </FlexContainer>
                  )}
                </FlexContainer>
                <hr />
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
                  {this.state.statusOptions.length === 0 && (
                    <LabledSingleSelect
                      label="Status"
                      styles={SingleStandardDropdownStyles}
                      options={[{ label: 'Loading...', value: '' }]}
                      defaultValue={{ label: 'Loading...', value: '' }}
                      value={this.state.userRole || undefined}
                      onSelection={this.handleRoleSelectChange}
                    />
                  )}
                  {this.state.statusOptions.length > 0 && (
                    <LabledSingleSelect
                      label="Status"
                      styles={SingleStandardDropdownStyles}
                      options={this.state.statusOptions}
                      defaultValue={null}
                      value={this.state.accountStatus || undefined}
                      onSelection={this.handleStatusSelectChange}
                    />
                  )}
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap>
                  <LabledAsyncSingleSelect
                    label="User Role"
                    styles={SingleStandardDropdownStyles}
                    loadOptions={this.loadRoleOptionsPromise}
                    defaultValue={{ label: 'Select a User Role...', value: '' }}
                    value={this.state.userRole || undefined}
                    onSelection={this.handleRoleSelectChange}
                  />
                  {!this.isAdminRoleSelected() && (
                    <LabledAsyncSingleSelect
                      label="Corporation"
                      styles={SingleStandardDropdownStyles}
                      loadOptions={this.loadCorporationPromise}
                      defaultValue={this.state.corporation || null}
                      value={this.state.corporation || undefined}
                      onSelection={this.handleCorporationSelectChange}
                    />
                  )}
                </FlexContainer>
                {!this.isAdminRoleSelected() && (
                  <>
                    <Paragraph text="Programs" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="filter-controls">
                      <div className="filter-controls-left">
                        <DropdownMenu
                          id="available-search-dropdown"
                          buttonText="Available Facility List Filter"
                          options={this.state.filteredFacilityProgramList}
                          onSelection={this.handleAvailableFacilitySelectChange}
                        />
                      </div>
                      <div className="filter-controls-right">
                        <DropdownMenu
                          id="selected-search-dropdown"
                          buttonText="Selected Facility List Filter"
                          options={this.state.filteredFacilityProgramList}
                          onSelection={this.handleSelectedFacilitySelectChange}
                        />
                      </div>
                    </FlexContainer>
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="listbox-flex-container">
                      <DualListPanel canFilter selected={this.state.selectedProgramIDs} options={this.state.filteredFacilityProgramList} onChange={this.onProgramSelectionChange} />
                    </FlexContainer>
                  </>
                )}
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="button-container --last">
                  <Button variant={ButtonVariant.SECONDARY} onClick={this.discardChanges}>
                    <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                  <Paragraph
                    text="You are responsible for ensuring that only
                                        permitted facility users are granted access to the
                                        Reflections site. Any unauthorized review, use,
                                        disclosure or distribution of this information is
                                        prohibited, and may be punishable by law."
                    fontColor={FontColors.DARK}
                    fontSize={FontSizes.REGULAR}
                    bold
                  />
                  <Button variant={ButtonVariant.PRIMARY} onClick={this.submitChanges} disabled={this.disableRegistration()}>
                    <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </FlexContainer>

                {this.state.saveError && (
                  <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.CENTER} align={AlignVariant.START} extraClasses="button-container">
                    <InlineText
                      text={this.state.saveErrorMessage ?? 'There was an error creating the user account, please try again.'}
                      fontColor={FontColors.HIGH_PRIORITY}
                      fontSize={FontSizes.REGULAR}
                      bold
                    />
                  </FlexContainer>
                )}

                {this.state.showAccessTypeSelectionWindow && (
                  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
                    <PopupWindow>
                      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                          <TertiaryHeader text="Facility User Registration" fontColor={FontColors.PRIMARY} />
                          <Button
                            variant={ButtonVariant.INVISIBLE}
                            onClick={(e) => {
                              e?.preventDefault();
                              e?.stopPropagation();
                              this.closeAccessTypeSelectionWindow();
                            }}
                          >
                            <X />
                          </Button>
                        </FlexContainer>
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="dropdown-input">
                          {this.state.accessTypeOptions.length === 0 && (
                            <LabledSingleSelect
                              label="Access Type"
                              styles={SingleStandardDropdownStyles}
                              options={[]}
                              defaultValue={{ label: 'Loading...', value: '0' }}
                              value={this.state.selectedAccessType ?? undefined}
                              onSelection={this.handleAccessTypeSelectChange}
                            />
                          )}
                          {this.state.accessTypeOptions.length > 0 && (
                            <LabledSingleSelect
                              label="Access Type"
                              styles={SingleStandardDropdownStyles}
                              options={this.state.accessTypeOptions}
                              defaultValue={this.state.selectedAccessType}
                              value={this.state.selectedAccessType ?? undefined}
                              onSelection={this.handleAccessTypeSelectChange}
                            />
                          )}
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-content">
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-buttons">
                          <Button variant={ButtonVariant.SECONDARY} onClick={this.closeAccessTypeSelectionWindow}>
                            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                          </Button>
                          <Button variant={ButtonVariant.PRIMARY} disabled={this.invalidAccessType()} onClick={this.saveAccessTypeSelection}>
                            <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                          </Button>
                        </FlexContainer>
                      </FlexContainer>
                    </PopupWindow>
                  </FlexContainer>
                )}
              </form>
            </FlexContainer>
          </PageLayout>
        )}
      </UserContext.Consumer>
    );
  }
}

FacilityUserEditPage.contextType = UserContext;

export default withRouter(FacilityUserEditPage);
