import ApiProxy from 'api/lib/ApiService';
import ApiProxyV2 from 'api/lib/ApiService/v2'
import ApiResponse from 'api/lib/models/ApiResponse';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import UserAccount from 'interfaces/users/userAccount';
import UserEntitlement from 'interfaces/users/userEntitlement';
import UserPatientRelationship from 'interfaces/users/userPatientRelationship';
import { SendEmailInvitationRequest } from 'interfaces/facilityUsers/sendEmailInvitationRequest';
import { URLS } from 'constants/appUrls';
import { UpdateAccountPatientRequest } from 'interfaces/patients/updateAccountPatientRequest';
import { CreateAccountRequest } from 'interfaces/facilityUsers/createAccountRequest';
import { isUHSEmail } from 'global_elements/Inputs/TextInput/lib/email';

class UserProxy {
  static getUserAccountInfo(
    accountID: number,
    success: (e: ApiResponse<UserAccount[]>) => void,
    failure: (e: ApiResponse<UserAccount[]>) => void,
  ): void {
    const url = `${URLS.MHO_API}/Account/${accountID}`;

    ApiProxy.get<UserAccount[]>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static getUserAccountInfoV2(accountID: number): Promise<ApiResponseV2<UserAccount[]>> {
    const url = `${URLS.MHO_API}/Account/${accountID}`;
    return ApiProxyV2.get<UserAccount[]>(url);
  }

  static postUserAccountInfo(
    data: CreateAccountRequest[],
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/Account`;

    ApiProxy.post<number>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static postUserAccountInfoV2(data: CreateAccountRequest[]): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/Account`;
    return ApiProxyV2.post(url, data);
  }

  static postUserAccountAccessType(accountId: number, accessTypeId: number, success: (e: ApiResponse<any>) => void, failure: (e: ApiResponse<any>) => void): void {
    const url = `${URLS.MHO_API}/AccTy/${accountId}/${accessTypeId}`;

    ApiProxy.post<number>(
      url,
      null,
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static postUserPatientInfo(
    data: UpdateAccountPatientRequest[],
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/PatientAcct`;

    ApiProxy.post<number>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static postUserPatientInfoV2(data: UpdateAccountPatientRequest[]): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/PatientAcct`;
    return ApiProxyV2.post(url, data);
  }

  static getUserEntitlements(
    accountID: number,
  ): Promise<UserEntitlement[]> {
    return new Promise<UserEntitlement[]>((resolve, reject) => {
      const url = `${URLS.MHO_API}/Entitlement/${accountID}`;
      ApiProxy.get<UserEntitlement[]>(url, (response: any) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
    * Retrieve the account's patient info.
    *
    * @param accountID ID of the user account.
    * @param success api success callback function.
    * @param failure api failure callback function.
    */
  static getUserPatientInfo(
    patientId: number,
    success: (e: ApiResponse<UserAccount[]>) => void,
    failure: (e: ApiResponse<UserAccount[]>) => void,
  ): void {
    const url = `${URLS.MHO_API}/PatientAcct/${patientId}`;

    ApiProxy.get<UserAccount[]>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static getUserPatientInfoV2(patientId: number): Promise<ApiResponseV2<UserAccount[]>> {
    const url = `${URLS.MHO_API}/PatientAcct/${patientId}`;
    return ApiProxyV2.get<UserAccount[]>(url);
  }

  /**
    * Retrieve the account's patient relationship info.
    *
    * @param accountID ID of the user account.
    * @param patientID ID of the user account.
    * @param success api success callback function.
    * @param failure api failure callback function.
    */
  static getUserPatientRelationshipInfo(
    accountID: number,
    patientID: number,
    success: (e: ApiResponse<UserPatientRelationship[]>) => void,
    failure: (e: ApiResponse<number>) => void,
  ): void {
    const url = `${URLS.MHO_API}/AccountPatient/${accountID}/${patientID}`;

    ApiProxy.get<UserPatientRelationship>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static getUserPatientRelationshipInfoV2(accountId: number, patientId: number): Promise<ApiResponseV2<UserPatientRelationship[]>> {
    const url = `${URLS.MHO_API}/AccountPatient/${accountId}/${patientId}`;
    return ApiProxyV2.get<UserPatientRelationship[]>(url);
  }

  /**
    * Save account patient relationship info.
    *
    * @param data api data to save
    * @param success api success callback function.
    * @param failure api failure callback function.
    */
  static saveUserPatientRelationshipInfo(
    data: any,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/AccountPatient`;

    ApiProxy.post<any>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static saveUserPatientRelationshipInfoV2(data: any): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/AccountPatient`;
    return ApiProxyV2.post(url, data);
  }

  static getEmailExistsInDatabase(
    email: string,
    success: (e: ApiResponse<string>) => void,
    failure: (e: ApiResponse<string>) => void,
  ): void {
    const url = `${URLS.MHO_API}/VerifyEmail?email=${email}`;

    ApiProxy.get<string>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static verifyEmailDomain(
    email: string,
    success: (e: ApiResponse<boolean>) => void,
    failure: (e: ApiResponse<boolean>) => void,
  ): void {
    const url = `${URLS.MHO_API}/VerifyEmailDomain?email=${email}`;

    ApiProxy.get<boolean>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static GetLoginPolicy(email: string): Promise<ApiResponseV2<string>> {
    const url = `${URLS.BASE_MHO_API}/Identity/Policy/${email}`;
    return ApiProxyV2.get<string>(url, undefined, false, true);
  }

  static linkPatientStay(
    patientID: number,
    pin: string,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/Link/${patientID}/${pin}`;

    ApiProxy.get<any>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static getAnonymousToken(
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/GetPinToken`;

    ApiProxy.get<number>(
      url,
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static validatePinWithDOB(
    token: string,
    pin: string,
    dob: string,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/VRegPin/${token}/${pin}/${dob}`;

    ApiProxy.get<number>(
      url,
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static verifyPinEmail(
    token: string,
    email: string,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/VerifyPinEmail/${token}?email=${email}`;

    ApiProxy.get<number>(
      url,
      (response: any) => {
        success(response);
      },
      (err: any) => {
        failure(err);
      },
    );
  }

  static sendInviteIfPinIsValid(token: string, pin: string, dob: string, email: string): Promise<ApiResponseV2<string>> {
    const url = `${URLS.MHO_API}/InvByVRegPin/${token}/${pin}/${dob}/${email}`;
    return ApiProxyV2.get<string>(url, undefined, false, true);
  }

  static validateDOB(
    dob: string,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/VRegDOB/${dob}`;

    ApiProxy.get<any>(
      url,
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static getHelpFaq(platform: number | null, type: number | null): Promise<any> {
    let url = `${URLS.MHO_API}/HelpFAQ`;
    if (platform) {
      url += `?platform=${platform}`;
    } else if (type) {
      url += `?type=${type}`;
    }
    return ApiProxyV2.get<any>(url);
  }

  static downloadHelpFile(
    fileName: string,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/HelpFile/${fileName}`;

    ApiProxy.get<any>(
      url,
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static SendEmailInvitation(
    data: SendEmailInvitationRequest,
    success: (e: ApiResponse<SendEmailInvitationRequest>) => void,
    failure: (e: ApiResponse<SendEmailInvitationRequest>) => void,
  ): void {
    let url = '';

    if (isUHSEmail(data.Email)) {
      url = `${URLS.BASE_MHO_API}/Identity/SendWelcome`;
    } else {
      url = `${URLS.BASE_MHO_API}/Identity/SendInvitation`;
    }

    ApiProxy.post<number>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      }, (response: any) => {
        failure(response);
      },
    );
  }

  static async getClientIp(): Promise<string> {
    const { ip } = await fetch('https://api.ipify.org?format=json', { method: 'GET' })
      .then((res) => res.json())
      .catch((error) => console.error(error));
    return ip || '0.0.0.0';
  }
}

export default UserProxy;
