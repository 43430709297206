import * as React from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import MHODateTime from 'domain/dateTime/MHODateTime';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { CoreMeasuresWorklistDataRow, CoreMeasuresWorklistTableProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import Utilities from 'api/lib/Utilities';

import { HoverTableStyles } from '../styles';

const NoDataComponent: JSX.Element = <InlineText text="No Results Found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const columns: TableColumn<CoreMeasuresWorklistDataRow>[] = [
  {
    name: 'Sample',
    id: 'Sample',
    selector: (row) => row.sample,
    sortable: true,
  },
  {
    name: 'Patient Name',
    id: 'Patient Name',
    selector: (row) => row.patientName,
    sortable: true,
  },
  {
    name: 'Account Number',
    id: 'Account Number',
    selector: (row) => row.accountNumber,
    sortable: true,
  },
  {
    name: 'Medical Record',
    id: 'Medical Record',
    selector: (row) => row.medicalRecordNumber,
    sortable: true,
  },
  {
    name: 'Admission Date',
    id: 'Admission Date',
    selector: (row) => row.dateAdmitted,
    sortable: true,
    format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.dateAdmitted) ? new MHODateTime(row.dateAdmitted).getFormattedCalendarDate() : row.dateAdmitted),
  },
  {
    name: 'Discharge Date',
    id: 'Discharge Date',
    selector: (row) => row.dateDischarged,
    sortable: true,
    format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.dateDischarged) ? new MHODateTime(row.dateDischarged).getFormattedCalendarDate() : row.dateDischarged),
  },
  {
    name: 'Age',
    id: 'Age',
    selector: (row) => row.age,
    sortable: true,
    sortFunction: (a, b) => {
      const age1 = parseInt(a.age, 10);
      const age2 = parseInt(b.age, 10);

      let comparison = 0;
      if (Number.isNaN(age1) && Number.isNaN(age2)) {
        comparison = 0;
      } else if (Number.isNaN(age2) || age1 > age2) {
        comparison = 1;
      } else if (Number.isNaN(age1) || age1 < age2) {
        comparison = -1;
      }
      return comparison;
    },
  },
  {
    name: 'Complete',
    id: 'Complete',
    selector: (row) => row.complete,
    sortable: true,
  },
  {
    name: 'SDOH',
    id: 'SDOH',
    selector: (row) => row.sdoh,
    sortable: true,
  },
];

const onSortWorklist = (selectedColumn: TableColumn<CoreMeasuresWorklistDataRow>, sortDirection: SortOrder, setSortFieldId: any, setSortFieldAsc: any): void => {
  Utilities.setCellTitles();
  setSortFieldId(selectedColumn.id);
  setSortFieldAsc(sortDirection === 'asc');
};

const CoreMeasuresWorklistTable = (props: CoreMeasuresWorklistTableProps): JSX.Element => (
  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.END} extraClasses={`core-measures-worklist-table ${props.isPrint ? 'print' : 'no-print'}`}>
    <DataTable
      dense
      highlightOnHover
      persistTableHead
      pagination={!props.isPrint}
      paginationDefaultPage={props.defaultPage}
      sortIcon={<SortIcon />}
      customStyles={HoverTableStyles}
      columns={columns}
      data={props.data}
      noDataComponent={NoDataComponent}
      onRowClicked={props.onRowClicked}
      onChangePage={(page: number, totalRows: number) => props.onChangePage?.(page, totalRows)}
      sortFunction={Utilities.caseInsensitiveSort}
      onSort={(selectedColumn: TableColumn<CoreMeasuresWorklistDataRow>, sortDirection: SortOrder) => {
        onSortWorklist(selectedColumn, sortDirection, props.setSortFieldId, props.setSortFieldAsc);
      }}
      className={props.isPrint ? 'print' : 'no-print'}
      defaultSortFieldId={props.defaultSortFieldID ?? ''}
      defaultSortAsc={props.defaultSortAsc ?? false}
    />
  </FlexContainer>
);

export default CoreMeasuresWorklistTable;
