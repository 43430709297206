import { FeatureFlagName } from 'config/featureFlags';
import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { UserRole, Roles } from 'constants/roles';
import {
  AnyRoute,
  AllRoutes,
  PatientRoute,
  PatientRoutes,
  ProviderRoute,
  ProviderRoutes,
  ExecutiveRoute,
  ExecutiveRoutes,
  SuperAdminRoute,
  SuperAdminRoutes,
  AdminRoute,
  AdminRoutes,
} from 'constants/routes';

const PATIENT_NAV = [PatientRoutes.DASHBOARD, PatientRoutes.RESOURCES, PatientRoutes.HELP] as PatientRoute[];

const PROVIDER_NAV = [
  ProviderRoutes.DASHBOARD,
  ...(FeatureFlagManager.get<boolean>(FeatureFlagName.isGroupTherapyEnabled) ? [AllRoutes.GROUP_THERAPY_BASE] : []),
  ProviderRoutes.PRINTABLE_ASSESSMENTS,
  ProviderRoutes.HELP,
] as ProviderRoute[];

const FACILITY_DIRECTOR_NAV = [
  ExecutiveRoutes.DASHBOARD,
  ExecutiveRoutes.FACILITY_USER_ACCOUNTS,
  ...(FeatureFlagManager.get<boolean>(FeatureFlagName.isGroupTherapyEnabled) ? [AllRoutes.GROUP_THERAPY_BASE] : []),
  ExecutiveRoutes.REPORTS,
  ExecutiveRoutes.PRINTABLE_ASSESSMENTS,
  ExecutiveRoutes.HELP,
  ExecutiveRoutes.FACILITY_LIBRARY,
] as ExecutiveRoute[];

const EXECUTIVE_ADMIN_NAV = [
  ExecutiveRoutes.DASHBOARD,
  ExecutiveRoutes.FACILITY_USER_ACCOUNTS,
  ExecutiveRoutes.REPORTS,
  ExecutiveRoutes.PRINTABLE_ASSESSMENTS,
  ExecutiveRoutes.HELP,
  ExecutiveRoutes.FACILITY_LIBRARY,
] as ExecutiveRoute[];

const ADMIN_NAV = [AdminRoutes.DASHBOARD, AdminRoutes.FACILITY_USER_ACCOUNTS, AdminRoutes.REPORTS, AdminRoutes.FACILITY_LIBRARY] as AdminRoute[];

const SUPER_ADMIN_PRIMARY_NAV = [
  SuperAdminRoutes.DASHBOARD,
  SuperAdminRoutes.FACILITY_USER_ACCOUNTS,
  SuperAdminRoutes.REPORTS,
  SuperAdminRoutes.ASSESSMENT_EDITOR,
  SuperAdminRoutes.FACILITY_LIBRARY,
] as SuperAdminRoute[];

export const ADMIN_REFERENCE_NAV = [SuperAdminRoutes.HELP, SuperAdminRoutes.RESOURCES] as SuperAdminRoute[];

export const GetNavOptions = (role: UserRole | null | undefined, accountID: string | null | undefined, hasCoreMeasuresAccess: boolean, hasOutcomesAccess: boolean): AnyRoute[] => {
  let routes: AnyRoute[] = [];

  switch (role) {
    default:
      break;
    case Roles.PATIENT:
      // Array.from() is used to create a shallow copy of the array
      routes = Array.from(PATIENT_NAV);
      break;
    case Roles.PROVIDER:
      routes = Array.from(PROVIDER_NAV);
      break;
    case Roles.FACILITY_DIRECTOR:
      routes = Array.from(FACILITY_DIRECTOR_NAV);
      break;
    case Roles.FACILITY_EXECUTIVE:
    case Roles.CORPORATION:
    case Roles.SUPER_CORPORATION:
      routes = Array.from(EXECUTIVE_ADMIN_NAV);
      break;
    case Roles.ADMIN:
      routes = Array.from(ADMIN_NAV);
      break;
    case Roles.SUPER_ADMIN:
      routes = Array.from(SUPER_ADMIN_PRIMARY_NAV);
      break;
  }

  // If a Core Measures facility is selected, need to include the CM Links
  if (hasCoreMeasuresAccess && FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled)) {
    routes.splice(1, 0, AllRoutes.CORE_MEASURES_WORKLIST);
    routes.splice(3, 0, AllRoutes.CORE_MEASURES_FACILITY_INFO);
  }

  // If user has outcomes access, add Patients Accounts link
  if (hasOutcomesAccess && role !== Roles.PATIENT) {
    routes.splice(1, 0, AllRoutes.PATIENT_ACCOUNTS);
  } else if (role !== Roles.PATIENT) {
    // If no outcomes access, remove Home link
    routes.splice(0, 1);
    // Remove printable assessments and group therapy routes
    const printAssessmentsIndex = routes.findIndex((x) => x === AllRoutes.PRINTABLE_ASSESSMENTS);
    if (printAssessmentsIndex >= 0) {
      routes.splice(printAssessmentsIndex, 1);
    }
    const groupTherapyIndex = routes.findIndex((x) => x === AllRoutes.GROUP_THERAPY_BASE);
    if (groupTherapyIndex >= 0) {
      routes.splice(groupTherapyIndex, 1);
    }
  }

  return routes;
};

export const GetNavText = (route: AnyRoute): string => {
  switch (route) {
    default:
      return 'Route Not Found';
    case AllRoutes.ACCOUNT:
      return 'Account';
    case AllRoutes.ASSESSMENT_EDITOR:
      return 'Assessment Editor';
    case AllRoutes.DASHBOARD:
      return 'Home';
    case AllRoutes.HELP:
      return 'Help';
    case AllRoutes.FACILITY_LIBRARY:
      return 'Facility Library';
    case AllRoutes.PATIENT_ACCOUNTS:
      return 'Patient Accounts';
    case AllRoutes.GROUP_THERAPY_BASE:
      return 'Group Therapy Sessions';
    case AllRoutes.PRINTABLE_ASSESSMENTS:
      return 'Printable Assessments';
    case AllRoutes.REPORTS:
      return 'Reports';
    case AllRoutes.RESOURCES:
      return 'Resources';
    case AllRoutes.FACILITY_USER_ACCOUNTS:
      return 'Facility User Accounts';
    case AllRoutes.CORE_MEASURES_WORKLIST:
      return 'Core Measures Home';
    case AllRoutes.CORE_MEASURES_FACILITY_INFO:
      return 'Core Measures Facility Info';
  }
};
